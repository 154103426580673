<style lang="scss">
  .vc-chrome-toggle-btn {
    display: none !important;
  }
  .legal-error{
    p, a{
      color:inherit;
    }
  }
</style>
<template>
  <div :style="{backgroundColor: backgroundPreview }">
    <div
      v-if="loading"
      class="w-full px-10 text-center"
    >
      <img
        src="@/assets/img/icons/loader.svg"
        width="50"
        class="mt-32 text-center"
      >
    </div>
    <div v-else>
      <div v-if="!loadError && form">
        <form
          id="lead"
          class="py-6 px-6"
          @submit.prevent="save"
        >
          <div
            v-for="(field, index) in form.fields"
            :key="field.id"
            class="container mx-auto max-w-md items-start content-start flex mb-5"
          >
            <div class="w-full">
              <div class="flex justify-between items-center mb-2">
                <label
                  class="block"
                  :style="{ color: design.labelText.hex }"
                >
                  {{ field.label }} <span
                    v-if="field.is_required"
                    class="float-right text-grey"
                    :style="{ color: design.labelText.hex }"
                  >*</span>
                </label>
                <div class="flex items-center">
                  <v-checkbox
                    v-if="field.type == 'datepicker' && field.is_multi_days"
                    id="multi-days"
                    v-model="multiDay"
                    name="multi-days"
                    :checked="multiDay"
                    :label="getTranslatedLabel"
                    :text-style="{ color: design.labelText.hex }"
                  />
                </div>
              </div>
              <input
                v-if="field.type == 'text' && field.mapping == 'email'"
                v-model.lazy="field.value"
                v-validate="{ required: field.is_required, email: true }"
                class="w-full lead"
                type="text"
                :name="field.name"
                :style="{ color:design.inputText.hex, borderColor: errors.first(field.name) ? '#FF0000' : design.borderColor.hex, backgroundColor:design.inputBackground.hex, borderRadius: design.inputRadius+'px' }"
              >
              <input
                v-if="field.type == 'text' && field.mapping != 'email'"
                v-model="field.value"
                v-validate="{ required: field.is_required }"
                class="w-full lead"
                type="text"
                :name="field.name"
                :style="{ color:design.inputText.hex, borderColor: errors.first(field.name) ? '#FF0000' : design.borderColor.hex, backgroundColor:design.inputBackground.hex, borderRadius: design.inputRadius+'px' }"
              >
              <textarea
                v-if="field.type == 'textarea'"
                v-model="field.value"
                v-validate="{ required: field.is_required }"
                :name="field.name"
                :style="{ color: design.inputText.hex, borderColor: errors.first(field.name) ? '#FF0000' : design.borderColor.hex, backgroundColor: design.inputBackground.hex, borderRadius: design.inputRadius+'px' }"
                @input="form.fields[index].value = $event.target.value"
              />
              <v-date-picker
                v-if="field.type == 'datepicker' && !multiDay"
                v-model="field.value"
                v-validate="{ required: field.is_required }"
                class="datepicker"
                :name="field.name"
                :popover="{ visibility: 'click' }"
                :masks="{L: 'WWW, MMM D, YYYY'}"
                :input-props="{
                  style: 'border-color:' + (errors.first(field.name) ? '#FF0000' : design.borderColor.hex) +
                    '; color:' + design.inputText.hex +
                    '; background-color:' + design.inputBackground.hex +
                    '; border-radius:' + design.inputRadius + 'px'
                }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :style="{
                      color: design.inputText.hex,
                      borderColor: errors.first(field.name) ? '#FF0000' : design.borderColor.hex,
                      backgroundColor: design.inputBackground.hex,
                      borderRadius: design.inputRadius + 'px'
                    }"
                    :value="inputValue"
                    v-on="inputEvents"
                  >
                </template>
              </v-date-picker>
              <!-- For multi-days -->
              <div
                v-if="field.type == 'datepicker' && multiDay"
                class="flex"
              >
                <!-- Start date picker -->
                <v-date-picker
                  v-model="field.startDate"
                  class="datepicker mr-2 w-full"
                  :popover="{ visibility: 'click' }"
                  :masks="{L: 'WWW, MMM D, YYYY'}"
                  :input-props="{
                    style: 'border-color:' + (errors.first(field.name) ? '#FF0000' : design.borderColor.hex) +
                      '; color:' + design.inputText.hex +
                      '; background-color:' + design.inputBackground.hex +
                      '; border-radius:' + design.inputRadius + 'px'
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      :style="{
                        color: design.inputText.hex,
                        borderColor: errors.first(field.name) ? '#FF0000' : design.borderColor.hex,
                        backgroundColor: design.inputBackground.hex,
                        borderRadius: design.inputRadius + 'px'
                      }"
                      :value="inputValue"
                      placeholder="Start date"
                      v-on="inputEvents"
                    >
                  </template>
                </v-date-picker>

                <!-- End date picker -->
                <v-date-picker
                  v-model="field.endDate"
                  class="datepicker w-full"
                  :popover="{ visibility: 'click' }"
                  :masks="{L: 'WWW, MMM D, YYYY'}"
                  :input-props="{
                    style: 'border-color:' + (errors.first(field.name) ? '#FF0000' : design.borderColor.hex) +
                      '; color:' + design.inputText.hex +
                      '; background-color:' + design.inputBackground.hex +
                      '; border-radius:' + design.inputRadius + 'px'
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      :style="{
                        color: design.inputText.hex,
                        borderColor: errors.first(field.name) ? '#FF0000' : design.borderColor.hex,
                        backgroundColor: design.inputBackground.hex,
                        borderRadius: design.inputRadius + 'px'
                      }"
                      :value="inputValue"
                      placeholder="End date"
                      v-on="inputEvents"
                    >
                  </template>
                </v-date-picker>
              </div>

              <div v-if="field.type == 'dropdown'">
                <div
                  class="select-container tall w-full"
                >
                  <select
                    v-model="field.value"
                    :style="{
                      color: design.inputText.hex,
                      borderColor: errors.first(field.name) ? '#FF0000' : design.borderColor.hex,
                      backgroundColor: design.inputBackground.hex,
                      borderRadius: design.inputRadius+'px'
                    }"
                  >
                    <option
                      v-for="option in field.options"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-if="field.type == 'workflow'">
                <div
                  class="select-container tall w-full"
                >
                  <select
                    v-model="field.value"
                    :style="{
                      color: design.inputText.hex,
                      borderColor: errors.first(field.name) ? '#FF0000' : design.borderColor.hex,
                      backgroundColor: design.inputBackground.hex,
                      borderRadius: design.inputRadius+'px'
                    }"
                  >
                    <option
                      v-for="option in design.enabledWorkflows"
                      :value="option.id"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="design.isLegalCheck"
            class="max-w-md mx-auto mb-5"
          >
            <div
              class="custom-checkbox inline-block mt-2"
            >
              <input
                id="legal"
                v-validate="'required'"
                name="legal"
                class="styled-checkbox"
                type="checkbox"
              >
              <label
                id="lega-label"
                for="legal"
                :class="[errors.first('legal') ? 'legal-error' : '']"
                :style="[errors.first('legal') ? {'color': design.errorColor.hex } : {'color': design.legalBoxColor ? design.legalBoxColor.hex : '#000' }]"
                v-html="design.legalText"
              ></label>
            </div>
          </div>
          <div
            class="w-full max-w-md mx-auto my-2 text-center"
          >
            <input
              type="submit"
              name="submit"
              :value="design.buttonLabel"
              class="btn"
              :style="{backgroundColor: design.buttonBackground.hex, color: design.buttonColor.hex}"
            >
          </div>
        </form>
        <div
          v-if="success"
          class="success mt-5 text-center"
        >
          <div
            class="bg-blue-washed-light mx-auto px-3 py-3 inline-block rounded"
            :style="{backgroundColor: thankYouBackgroundPreview, color: design.thankYouTextColor.hex}"
          >
            <pre>{{ design.thankYouText }}</pre>
          </div>
        </div>
      </div>
      <div
        v-else
        class="text-center bg-blue-washed-light px-10 py-10 rounded mt-5 text-sm"
      >
        There is an error loading the form, <br> please double check your embed link. <br><br>
        <router-link to="/">
          <img
            src="/images/logo.png"
            width="88"
          >
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { addDays, format } from 'date-fns'
import { parseFromTimeZone } from 'date-fns-timezone'

export default {
	data(){
  	return {
      loading:false,
      loadError: false,
      saving: false,
  		form: {},
  		company_id: null,
  		success: false,
      successMessage: null,
  		token: this.$route.params.token,
      brandButtonTextColor: '',
      brandButtonBackground: '',
      multiDay: false,
      design: {
        language: 'en',
        backgroundColor: { hex: '#F5F5F5'},
        isBackgroundTransparent: true,
        labelText: { hex: '#000000'},
        inputText: { hex: '#4A4A4A'},
        requiredText: { hex: '#D1D1D1'},
        inputBackground: { hex: '#FFFFFF'},
        borderColor: { hex: '#D1D1D1'},
        inputRadius: 4,
        buttonLabel: 'Submit',
        buttonColor: { hex: '#FFFFFF'},
        buttonBackground: { hex: '#0FCE83'},
        errorColor: { hex: '#FF0000'},
        legalBoxColor: { hex: '#FF0000'},
        isThankYouBackgroundTransparent: false,
        thankYouBackground: { hex: '#F3F6FF'},
        thankYouTextColor: { hex: '#000000'},
        thankYouText: 'Thank you for your request. We will contact you as soon as possible!',
        isRedirectOn: false,
        redirectLink: '',
      }
  	}
	},
  computed: {
    getTranslatedLabel() {
      return this.$translate('multiDay', this.design.language)
    },
    backgroundPreview(){
      if(this.design.isBackgroundTransparent){
        return 'transparent'
      }
      return this.design.backgroundColor.hex
    },
    thankYouBackgroundPreview(){
      if(this.design.isThankYouBackgroundTransparent){
        return 'transparent'
      }
      return this.design.thankYouBackground.hex
    },
  },
	async mounted(){
    this.getData()
	},
	methods: {
    dateFormatter(value) {
      return format(value, 'MMM Do, YYYY')
    },
    async getData(){
      this.loading = true
      try {

        const { data } = await this.$api.get('leadform').get(this.token)

        // If setting options is empty, check fall back to old settings with brand colors
        if(data.form.setting){
          this.design = data.form.setting.options
        } else {
          this.design.buttonBackground.hex = data.company.brandButtonBackground
          this.design.buttonColor.hex = data.company.brandButtonTextColor
        }

        this.design.thankYouText = data.form.success_message
        this.form = data.form
        this.form.fields.forEach(f => {
          if(f.type == 'dropdown'){
            f.value = f.options[0]
          }
        })
        this.form.fields.forEach(f => {
          if(f.type == 'workflow'){
            f.value = this.design.enabledWorkflows[0].id
          }
        })

        this.formId = data.form.id
        this.companyId = data.form.company_id
        this.loading = false

      } catch(e){
        this.$toasted.global.api_error(e)
      }
    },
		save() {
      if(!this.saving){
        this.$validator.validate().then(result => {
          if(result){
            this.doSave()
          }
        })
      }
		},
    async doSave(saveAndEditLater = false) {

      let that = this
      this.saving = true
      Object.keys(this.form.fields).forEach(function (item) {
        if(that.form.fields[item].type == 'datepicker') {
          that.form.fields[item].value = (that.form.fields[item].value) ? parseFromTimeZone(that.form.fields[item].value, { timeZone: 'Etc/UTC' }) : null

          // if is multi date, parse start and end date
          if(that.form.fields[item].is_multi_days && that.multiDay) {
            that.form.fields[item].startDate = (that.form.fields[item].startDate) ? parseFromTimeZone(that.form.fields[item].startDate, { timeZone: 'Etc/UTC' }) : null
            that.form.fields[item].endDate = (that.form.fields[item].endDate) ? parseFromTimeZone(that.form.fields[item].endDate, { timeZone: 'Etc/UTC' }) : null

            // combine them into one value
            that.form.fields[item].value = that.form.fields[item].startDate + ' - ' + that.form.fields[item].endDate
          }
        }
      })

      try {
        const postData = {
          form: this.form,
          formId: this.formId,
          companyId: this.companyId
        }

        const { data } = await this.$api.get('lead').submit(postData)

        Object.keys(this.form.fields).forEach(function (item) {
          that.form.fields[item].value = ''
        })

        this.saving = false

        if(this.design.isRedirectOn){
          window.top.location.href = this.design.redirectLink
        } else {
          this.success = true
          this.$nextTick(() => {
              this.errors.clear()
              this.$nextTick(() => {
                  this.$validator.reset()
              })
          })
        }

      } catch(e){
        this.saving = false
        this.$toasted.global.api_error(e)
      }
    },
	},
}
</script>
